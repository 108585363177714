import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';

import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class RequestsService {
  constructor(private httpClient: HttpClient, private authService: AuthService) {}

  public async get<T>(route: string): Promise<T | undefined> {
    const headers = await this.getAuthorizationHeader();
    return this.httpClient
      .get<T>(`${this.rootEndPoint}/${route}`, {
        headers
      })
      .toPromise();
  }

  public async post<T>(route: string, body?: any, bypassJWT?: boolean): Promise<T | undefined> {
    const headers = bypassJWT ? {} : await this.getAuthorizationHeader();
    return this.httpClient
      .post<T>(`${this.rootEndPoint}/${route}`, body || {}, {
        headers
      })
      .toPromise();
  }

  public async patch<T>(route: string, body: any, bypassJWT?: boolean): Promise<T | undefined> {
    const headers = bypassJWT ? {} : await this.getAuthorizationHeader();
    return this.httpClient
      .patch<T>(`${this.rootEndPoint}/${route}`, body, {
        headers
      })
      .toPromise();
  }

  public async delete(route: string): Promise<any> {
    const headers = await this.getAuthorizationHeader();
    return this.httpClient
      .delete(`${this.rootEndPoint}/${route}`, {
        headers
      })
      .toPromise();
  }

  private async getAuthorizationHeader(): Promise<any> {
    const authorization = await this.authService.getAuthAsync().then(user => user.getIdToken(true));
    return { authorization };
  }

  // This getter has to be here to avoid some circular dependencies
  private get rootEndPoint(): string {
    return environment.backendUrl;
    /* ###M */
    // return 'http://localhost:3001';
  }
}
