import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap';

import { AuthService } from '../../../services/auth/auth.service';
import { UserSessionService } from '../../../services/session/user-session.service';

export type ModalPasswordOptions = ModalOptions & {
  initialState: {
    onConfirmCallback: Function;
    onCancelCallback?: Function;
  };
};
@Component({
  selector: 'app-modal-password',
  templateUrl: './modal-password.component.html',
  styleUrls: ['./modal-password.component.scss']
})
export class ModalPasswordComponent implements OnInit {
  passswordForm: FormControl;
  error: string;
  onConfirmCallback: Function;
  onCancelCallback: Function;

  constructor(private modalRef: BsModalRef, private authService: AuthService, private _user: UserSessionService) {
    this.passswordForm = new FormControl('', Validators.required);
  }

  ngOnInit() {}

  hide() {
    if (this.onCancelCallback) {
      this.onCancelCallback();
    }
    this.modalRef.hide();
  }

  confirmReauth() {
    if (this.passswordForm.valid) {
      this.error = null;
      this.authService
        .getCredential(this._user.email, this.passswordForm.value)
        .then(creds => this.authService.reauthenticateWithCredential(creds))
        .then(() => {
          if (this.onConfirmCallback) {
            this.onConfirmCallback();
          }
          this.modalRef.hide();
        })
        .catch(error => {
          if (error.code === 'auth/wrong-password') {
            // ###T
            this.error = 'Wrong password';
          }
        });
    }
  }
}
