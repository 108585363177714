import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Subscription } from 'rxjs';

import { AuthService } from '../shared/services/auth/auth.service';
import { FeedbackService } from '../shared/services/feedback/feedback.service';
import { ProtocolDroidService } from '../shared/services/protocol-droid/services/protocol-droid.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  @ViewChild('password', { static: true }) passwordRef: ElementRef;
  longSession = new FormControl();
  credentials: any;
  redirection: string;
  customMessage: string;
  emailInput: string;
  signInError: string;
  private subscriptions: Subscription[];

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private protocolDroidService: ProtocolDroidService,
    private feedbackService: FeedbackService
  ) {
    this.subscriptions = [];
    this.longSession = new FormControl();
  }

  get isPasswordHide(): boolean {
    return this.passwordRef?.nativeElement?.type === 'password';
  }

  ngOnInit() {
    setTimeout(() => {
      this.feedbackService.hideSpinner();
    }, 1000);
    this.subscriptions.push(
      this.authService.getAuth().subscribe(user => {
        this.authService.handleUserArrival(user, { longSession: this.longSession.value, redir: this.redirection });
      }),
      this.route.queryParamMap.subscribe((params: ParamMap) => {
        this.redirection = params.get('rd') as string;
        this.customMessage = params.get('message') as string;
        this.emailInput = params.get('email') as string;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  loginWithCredentials(event: any, email: string, password: string): void {
    // Necessary to stop events from ENTER key
    event.stopPropagation();
    event.preventDefault();
    this.signInError = null;
    this.authService.loginWithCredentials({ email, password }).catch(() => {
      this.signInError = this.protocolDroidService.translate('login.invalid-credentials');
    });
  }

  googleAuth(): void {
    this.authService.signinWithGoogle();
  }

  handleSignInError(): boolean {
    if (this.signInError) {
      return true;
    } else return false;
  }

  setTypeInputPassword(): void {
    const typePassword = this.passwordRef?.nativeElement?.type;
    if (typePassword) {
      this.passwordRef.nativeElement.type = typePassword === 'password' ? 'text' : 'password';
    }
  }
}
