import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { get } from 'lodash';
import { Subscription } from 'rxjs';

import { ProtocolDroidService } from '../../shared/services/protocol-droid/services/protocol-droid.service';
import { UserService } from '../../shared/services/user/user.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  @Input() resetCode: string;
  @Input() state: 'pending' | 'default' | 'success' | 'error';
  @Input() userEmail: string;
  resetPasswordError: string;

  passwords: FormGroup = new FormGroup({
    newPassword: new FormControl(null, Validators.required),
    confirmNewPassword: new FormControl(null, Validators.required)
  });

  private subscriptions: Subscription[];

  get newPassword(): FormControl {
    return this.passwords.get('newPassword') as FormControl;
  }

  get confirmNewPassword(): FormControl {
    return this.passwords.get('confirmNewPassword') as FormControl;
  }

  constructor(private userService: UserService, private protocolDroidService: ProtocolDroidService) {
    this.subscriptions = [];
  }

  ngOnInit() {
    this.subscriptions.push(
      this.passwords.valueChanges.subscribe(() => {
        if (this.newPassword.dirty && this.confirmNewPassword.dirty) this.checkPasswords();
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  resetPassword(): void {
    if (this.passwords.invalid || !this.checkPasswords()) return;
    if (!this.resetCode || !this.userEmail) return;

    this.resetPasswordError = null;
    this.state = 'pending';

    this.userService
      .resetPassword(this.resetCode, this.userEmail, this.newPassword.value)
      .then(() => {
        this.state = 'success';
      })
      .catch(error => {
        this.resetPasswordError = this.protocolDroidService.translate('errors.' + get(error, 'error.response.code'));
        this.state = 'default';
      });
  }

  checkPasswords(): boolean {
    if (this.newPassword.value !== this.confirmNewPassword.value) {
      this.resetPasswordError = this.protocolDroidService.translate('misc.errors.passwords-matching');
      this.passwords.setErrors({ notMatchingError: true });
      return false;
    } else {
      this.resetPasswordError = null;
      this.passwords.setErrors(null);
      return true;
    }
  }
}

// auth/invalid-action-code
