<button
  class="button-icon"
  [disabled]="disabled"
  [ngStyle]="buttonStyle"
  [ngClass]="{
    disabled: disabled,
    active: active,
    small: getClassSmall,
    'no-background': noBackground,
    'narrow-horizontal-padding': !square,
    'no-radius': borderRadius === 'none',
    'less-radius': borderRadius === 'less',
    'normal-radius': borderRadius === 'normal',
    'full-radius': borderRadius === 'full'
  }"
  (click)="onClick.emit($event)"
>
  <ng-content></ng-content>
</button>
