/* tslint:disable */
export const environment = {
  production: true,
  development: false,
  name: 'preproduction',

  products: {
    chatbot: 'https://app-preprod.ideta.io',
    privateReplies: 'https://private-replies-preprod.ideta.io',
    webhooks: 'https://webhooks-preprod.ideta.io',
    livechat: 'https://livechat-preprod.ideta.io',
    inbox: 'https://inbox-preprod.ideta.io',
    linkedIn: 'https://linkedin.ideta.io/',
    sentimentAnalysis: 'https://api-portal.ideta.io/catalog/prd_t83al06y66emm5k5',
    workflowAnalysis: 'https://api-portal.ideta.io/catalog/prd_hzusd8o2g4sy10d4'
  },

  logos: {
    chatbot: 'assets/img/chatbot.svg',
    privateReplies: 'assets/img/autocomment.png',
    webhooks: 'assets/img/webhooks.svg',
    livechat: 'assets/img/livechat.svg',
    inbox: 'assets/img/inbox.svg',
    linkedIn: 'assets/img/linkedIn_ai.png',
    sentimentAnalysis: 'assets/img/sentiment-analysis.png',
    workflowAnalysis: 'assets/img/workflow-analysis.png'
  },

  domain: 'https://connect-preprod.ideta.io',
  backendUrl: 'https://api-preprod.ideta.io',
  colors: {
    primary: '#01a0c7',
    secondary: '#183255',
    tertiary: '#6c757d',
    valid: '#1abc9c',
    invalid: '#e46664',
    gray1: '#FFFFFF',
    gray2: '#FAFAFA',
    gray3: '#F5F5F5',
    gray4: '#EAE9E9',
    gray5: '#D9D9D9',
    gray6: '#BFBFBF',
    gray7: '#8C8C8C',
    gray8: '#626262',
    gray9: '#262626',
    grayText: '#545454',
    background: '#F7F7F7',
    white: '#FFFFFF',
    tanukiBrown: '#5A2A0A',
    primaryDark: '#007296',
    primaryLightDark: '#99C7D5',
    primaryNeutral: '#01A0C7',
    primaryExtraLight: '#E6F1F5',
    secondaryDark: '#008B6D',
    secondaryLightDark: '#99D1C5',
    secondaryNeutral: '#1EBC9B',
    secondaryExtraLight: '#E6F3F0',
    warningsDark: '#D34324',
    warningsNeutral: '#F28123',
    warningsLight: '#FFD75F'
  },

  firebase: {
    apiKey: 'AIzaSyCb58nzKGLBd3B_Fnivmp2v9DOsUVSYvUU',
    authDomain: 'app-preprod.ideta.io',
    databaseURL: 'https://ideta-preprod.firebaseio.com',
    projectId: 'ideta-preprod',
    storageBucket: 'ideta-preprod.appspot.com',
    messagingSenderId: '6272298926'
  },

  facebook: {
    appId: '759597507758306',
    xfbml: true,
    version: 'v13.0',
    cookie: true,
    scopes: [
      'public_profile',
      'email',
      'pages_messaging',
      'pages_show_list',
      'pages_manage_metadata',
      'pages_read_engagement'
    ]
  },

  instagram: {
    scopes: [
      'public_profile',
      'email',
      'pages_messaging',
      'pages_show_list',
      'pages_manage_metadata',
      'pages_read_engagement',
      'instagram_basic',
      'instagram_manage_messages',
      'pages_show_list'
    ]
  },

  stripe: {
    clientId: 'ca_EsDZeAWDsKRkjqwfzPgzwVnOko3oRxRZ',
    secretKey: 'pk_test_6pRNASCoBOKtIshFeQd4XMUh',
    self_secretKey: 'pk_live_ktk2HFr1a81xbh8P7cl87ndZ'
  },

  rollbar: {
    active: true,
    accessToken: '9631bf98995142d2a35e9094336ebd13',
    buildVersion: 'ca51c5f62a'
  },

  defaults: {
    user: {
      avatarUrl: 'assets/img/avatar.svg'
    }
  },
  appSettings: {
    minWidth: 374
  }
};
/* tslint:enable */
