<div class="modal-body d-flex h-100">
  <div class="message-title" *ngIf="title">{{ title }}</div>
  <div class="message-container row align-items-center">
    <div
      class="modal-icon-container col-12 col-md-4 col-xl-3 d-flex"
      [ngClass]="'modal-' + decoration"
    >
      <img
        *ngIf="decoration !== 'none'"
        class="modal-icon"
        [src]="'assets/img/' + decoration + '.png'"
        [attr.alt]="decoration"
      />
    </div>
    <div
      class="message-content text-center text-md-left my-3 my-md-0"
      [innerHtml]="message"
    ></div>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    (click)="onBack()"
    class="btn btn-sm btn-light"
    *ngIf="back"
    appProtocolDroid="misc.modals.back"
    placement="top"
    container="body"
  >
    Back
  </button>
  <button
    type="button"
    (click)="dismiss()"
    class="btn btn-sm btn-light"
    *ngIf="type === 'message'"
    appProtocolDroid="misc.modals.dismiss"
  >
    Fermer
  </button>
  <button
    type="button"
    (click)="dismiss()"
    class="btn btn-cancel"
    *ngIf="type === 'confirm'"
    appProtocolDroid="misc.modals.cancel"
  >
    Annuler
  </button>
  <button
    type="button"
    (click)="confirm()"
    class="btn btn-primary"
    *ngIf="type === 'confirm'"
    appProtocolDroid="misc.modals.confirm"
  >
    Confirmer
  </button>
</div>
