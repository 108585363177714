<!-- ###T -->
<div class="modal-header">Authenticate</div>
<div class="modal-body">
  <div class="text-center mb-2">
    To execute this action, please reauthenticate first.
  </div>
  <!-- ###T -->
  <input
    class="form-control"
    type="password"
    placeholder="Password..."
    [formControl]="passswordForm"
  />
  <div class="text-danger" *ngIf="error">{{ error }}</div>
</div>
<div class="modal-footer">
  <div class="window-cta-container">
    <button
      type="button"
      (click)="hide()"
      class="btn btn-sm btn-danger mx-2 px-3"
      appProtocolDroid="misc.modals.cancel"
    ></button>
    <button
      type="button"
      [disabled]="passswordForm.invalid"
      (click)="confirmReauth()"
      class="btn btn-sm btn-primary mx-2 px-3"
      appProtocolDroid="misc.modals.confirm"
    ></button>
  </div>
</div>
