import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tiny-bot-spinner',
  templateUrl: './tiny-bot-spinner.component.html',
  styleUrls: ['./tiny-bot-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TinyBotSpinnerComponent implements OnInit {
  @Input() inv?: boolean; // Inverse outline color (white)
  @Input() hideText?: boolean; // Hide "Loading..."
  @Input() size: string;
  @Input() backgroundColor: string;
  @Input() useOldStyle: boolean;

  loadingStyle: any;

  constructor() {
    this.size = '5.5rem';
    this.useOldStyle = false;
    this.inv = false;
    this.hideText = false;
  }

  ngOnInit() {
    this.loadingStyle = {
      width: this.size,
      'background-color': this.backgroundColor
    };
  }
}
