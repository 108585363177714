import { ErrorHandler, Injectable, InjectionToken, Injector } from '@angular/core';
import * as Rollbar from 'rollbar';

import { environment } from '../../../../environments/environment';

import { FeedbackService } from '../feedback/feedback.service';

const rollbarConfig = {
  enabled: environment.rollbar.active,
  accessToken: environment.rollbar.accessToken,
  captureUncaught: true,
  captureUnhandledRejections: true,
  captureIP: 'anonymize',
  verbose: true,
  payload: {
    environment: environment.name,
    client: {
      javascript: {
        code_version: environment.rollbar.buildVersion
      }
    }
  }
};

@Injectable({
  providedIn: 'root'
})
export class RollbarErrorHandler implements ErrorHandler {
  private readonly chunkError: RegExp;
  constructor(private injector: Injector) {
    this.chunkError = /Loading chunk [\d]+ failed/;
  }

  handleError(err: any): void {
    if (this.chunkError.test(err.message)) {
      const feedbackService = this.injector.get(FeedbackService);

      feedbackService
        .showMessage({
          message: 'misc.modals.version.desc',
          title: 'misc.modals.version.title',
          type: 'message',
          decoration: 'info'
        })
        .then(() => window.location.reload());
    } else {
      const rollbar = this.injector.get(RollbarService);
      rollbar.error(err.originalError || err);
      if (!environment.rollbar.active) {
        const cancelledPopUpRequest = err.message.includes('auth/cancelled-popup-request');
        if (!cancelledPopUpRequest) console.error(err.originalError || err);
      }
    }
  }
}

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}

export const RollbarService = new InjectionToken<Rollbar>('rollbar');
