<div class="signup-container ideta-scrollbar">
  <div class="signup-card ideta-scrollbar">
    <img class="signup-card-logo" src="/assets/img/ideta_logo.png" alt="Ideta" />
    <h1 appProtocolDroid="signup.title">
      Build better chatbots, faster. <br />
      Without coding.
    </h1>
    <div class="signup-card-info signup-card-privacy">
      <span appProtocolDroid="signup.privacy-info"
        >By signing up, you agree to Ideta <a href="https://www.ideta.io/privacy-policy">Privacy policy</a> and
        <a href="https://docs.google.com/document/d/1UrM2mVAxnZF7CXSHUwx-MDKXjueYSNHU0jynuQcQFw0/edit?usp=sharing"
          >CGU</a
        >
      </span>
    </div>
    <form class="signup-card-form" (keydown.enter)="createUser($event)">
      <input
        *ngIf="!lockEmailInput"
        class="form-control m-2"
        type="email"
        name="email"
        placeholder="Email..."
        [formControl]="emailForm"
      />
      <div *ngIf="lockEmailInput" class="text-left form-control m-2 disabled">
        {{ emailForm.value }}
      </div>

      <!-- Password -->
      <div class="password-bloc">
        <input
          class="form-control"
          type="password"
          name="password"
          placeholder="Password..."
          [formControl]="passwordForm"
          #password
        />
        <i
          class="fas"
          [class.fa-eye]="!isPasswordHide"
          [class.fa-eye-slash]="isPasswordHide"
          (click)="setTypeInputPassword()"
        ></i>
      </div>

      <!-- Password Confirm -->
      <div class="password-bloc">
        <input
          class="form-control"
          type="password"
          name="confirmPassword"
          placeholder="Confirm password..."
          [formControl]="confirmPasswordForm"
          #passwordConfirm
        />
        <i
          class="fas"
          [class.fa-eye]="!isPasswordConfirmHide"
          [class.fa-eye-slash]="isPasswordConfirmHide"
          (click)="setTypeInputPasswordConfirm()"
        ></i>
      </div>

      <span class="signup-card-form-error" *ngIf="!!signupError">{{ signupError }}</span>
    </form>

    <button
      type="submit"
      [disabled]="emailForm.invalid || passwordForm.invalid"
      class="btn btn-md btn-primary signup-card-btn signup-card-form-btn"
      (click)="createUser($event)"
      appProtocolDroid="signup.btn-signup"
    >
      Register
      <i class="fas fa-sign-in-alt"></i>
    </button>
    <span class="signup-card-info" appProtocolDroid="signup.separator">Or</span>
    <button type="button" class="btn signup-card-btn-google signup-card-btn" (click)="googleAuth()">
      <i class="fab fa-google"></i>
      <span appProtocolDroid="signup.btn-google">Sign up with google</span>
    </button>
    <div *ngIf="!hideSigninLink" class="signup-card-info text-nowrap">
      <span appProtocolDroid="signup.already">Already have an account ? </span
      ><a [routerLink]="['/login']" appProtocolDroid="signup.sign-in">Sign in</a>
    </div>
  </div>
</div>
