import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { from, of, Subscription } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { AuthService } from '../shared/services/auth/auth.service';

@Component({
  selector: 'app-auth-handler',
  templateUrl: './auth-handler.component.html',
  styleUrls: ['./auth-handler.component.scss']
})
export class AuthHandlerComponent implements OnInit {
  mode: string;
  actionCode: string;
  showResetPassword: boolean;
  showVerifyEmail: boolean;
  userEmail: string;
  state: 'pending' | 'default' | 'success' | 'error';

  private subscriptions: Subscription[];

  constructor(private route: ActivatedRoute, private authService: AuthService) {
    this.subscriptions = [];
    this.showResetPassword = false;
    this.showVerifyEmail = false;
    this.state = 'pending';
  }

  ngOnInit() {
    this.subscriptions.push(
      this.route.queryParamMap
        .pipe(
          switchMap(params => {
            this.mode = params.get('mode');
            this.actionCode = params.get('oobCode');

            const error = new Error('Invalid parameters');
            if (!this.mode || !this.actionCode) throw error;

            switch (this.mode) {
              case 'resetPassword':
                // Display reset password handler and UI.
                return from(this.authService.verifyPasswordCode(this.actionCode));
              case 'verifyEmail':
                // Display email verification handler and UI.
                return from(this.authService.applyActionCode(this.actionCode));
              default:
                throw error;
            }
          }),
          catchError(() => {
            this.state = 'error';
            return of(null);
          })
        )
        .subscribe(result => {
          if (!!result && this.mode === 'resetPassword') {
            this.state = 'default';
            this.userEmail = result;
            this.showResetPassword = true;
          } else if (this.mode === 'verifyEmail' && this.state !== 'error') {
            this.state = 'success';
            this.showVerifyEmail = true;
          }
        })
    );
  }
}
