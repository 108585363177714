import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { get } from 'lodash';
import { Subscription } from 'rxjs';

import { AuthService } from '../shared/services/auth/auth.service';
import { ProtocolDroidService } from '../shared/services/protocol-droid/services/protocol-droid.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit, OnDestroy {
  @ViewChild('password', { static: true }) passwordRef: ElementRef;
  @ViewChild('passwordConfirm', { static: true }) passwordConfirmRef: ElementRef;
  credentials: any;
  redirection: string;
  customMessage: string;
  signupError: string;
  emailForm: FormControl;
  passwordForm: FormControl;
  confirmPasswordForm: FormControl;
  botSignup: HTMLScriptElement;
  lockEmailInput: boolean;
  hideSigninLink: boolean;
  private subscriptions: Subscription[];

  get isPasswordHide(): boolean {
    return this.passwordRef?.nativeElement?.type === 'password';
  }

  get isPasswordConfirmHide(): boolean {
    return this.passwordConfirmRef?.nativeElement?.type === 'password';
  }

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private protocolDroidService: ProtocolDroidService
  ) {
    this.emailForm = new FormControl(null, [Validators.required, Validators.email]);
    this.passwordForm = new FormControl(null, Validators.required);
    this.confirmPasswordForm = new FormControl(null, Validators.required);
    this.subscriptions = [];
  }

  ngOnInit() {
    this.protocolDroidService.setLang(get(navigator, 'language') || get(navigator, 'userLanguage'));
    this.subscriptions.push(
      this.authService.getAuth().subscribe(user => {
        this.authService.handleUserArrival(user, { redir: this.redirection });
      }),
      this.route.queryParamMap.subscribe((params: ParamMap) => {
        this.redirection = params.get('rd') as string;
        this.customMessage = params.get('message') as string;
        const parsedEmail = (params.get('email') || '').replace(' ', '+');
        this.emailForm.patchValue(parsedEmail);
        const source = params.get('source');
        if (source === 'appsumo') {
          // If there is no email, the field is unlocked
          this.lockEmailInput = !!this.emailForm.value;
          this.hideSigninLink = true;
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  setTypeInputPassword(): void {
    const typePassword = this.passwordRef?.nativeElement?.type;
    if (typePassword) {
      this.passwordRef.nativeElement.type = typePassword === 'password' ? 'text' : 'password';
    }
  }

  setTypeInputPasswordConfirm(): void {
    const typePassword = this.passwordConfirmRef?.nativeElement?.type;
    if (typePassword) {
      this.passwordConfirmRef.nativeElement.type = typePassword === 'password' ? 'text' : 'password';
    }
  }

  createUser(event: any): void {
    // Necessary to stop events from ENTER key
    event.stopPropagation();
    event.preventDefault();
    if (this.emailForm.invalid || this.passwordForm.invalid) return;
    if (this.passwordForm.value !== this.confirmPasswordForm.value) {
      this.signupError = this.protocolDroidService.translate('misc.errors.passwords-matching');
      this.passwordForm.setErrors({ notMatchingError: true });
      this.confirmPasswordForm.setErrors({ notMatchingError: true });
      return;
    }

    this.signupError = null;
    this.passwordForm.setErrors(null);
    this.confirmPasswordForm.setErrors(null);
    this.authService
      .createUserWithEmailAndPassword({
        email: this.emailForm.value,
        password: this.passwordForm.value
      })
      .catch((error: any) => {
        this.signupError = this.protocolDroidService.translate('errors.' + get(error, 'error.response.code'));
      });
  }

  googleAuth(): void {
    this.authService.signinWithGoogle();
  }
}
