<div
  class="loading-container"
  [ngStyle]="loadingStyle"
  *ngIf="!useOldStyle; else oldStyle"
>
  <img *ngIf="!inv" src="assets/img/spinner-logo.gif" alt="Spinner logo" />
  <img *ngIf="inv" src="assets/img/spinner-logo-white.gif" alt="Spinner logo" />
</div>

<ng-template #oldStyle>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-20 -20 400 400"
    [style.width]="size"
    [style.height]="size"
  >
    <g class="svg-background-outline" [class.inverse]="inv">
      <path
        d="M359.86,175.89a61.85,61.85,0,0,0-52.45-61.13,128.18,128.18,0,0,0-255,0,61.85,61.85,0,0,0,0,122.26,128.18,128.18,0,0,0,255,0A61.85,61.85,0,0,0,359.86,175.89Z"
        transform="translate(1.5 1.5)"
      />
    </g>
    <g class="svg-background" [class.inverse]="inv">
      <circle cx="257.59" cy="82.19" r="9.34" />
      <circle cx="221.56" cy="82.19" r="9.34" />
      <path
        d="M272.09,114.05v95.42a92.16,92.16,0,1,1-184.32,0h0V114.05Z"
        transform="translate(1.5 1.5)"
      />
    </g>
    <g class="svg-heartbeat svg-primary">
      <path
        d="M242.59,158.88c-21.11-27.4-62.66-11.23-62.66,16.6,0-27.83-41.54-44-62.66-16.6-21.82,28.34-.31,78,62.66,106.48C242.9,236.85,264.41,187.22,242.59,158.88Z"
        transform="translate(1.5 1.5)"
      />
    </g>
  </svg>
  <div
    [hidden]="hideText"
    class="placeholder-text"
    appProtocolDroid="misc.loading"
  >
    Loading ...
  </div>
</ng-template>
