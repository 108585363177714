<div class="change-password-container">
  <img
    class="change-password-logo"
    src="/assets/img/ideta_logo.png"
    alt="Ideta"
  />
  <h1 appProtocolDroid="auth-handler.reset-password">Reset your password</h1>
  <i class="fas fa-circle-notch fa-spin" *ngIf="state === 'pending'"></i>
  <form
    [formGroup]="passwords"
    class="change-password-form-container"
    *ngIf="state === 'default'"
    (ngSubmit)="resetPassword()"
  >
    <label
      class="px-2 mt-2 form-label"
      appProtocolDroid="forgot-password.new-password"
      >New Password</label
    >
    <input
      class="form-control m-2"
      type="password"
      name="newPassword"
      formControlName="newPassword"
      appProtocolDroidAttribute="placeholder"
      appProtocolDroid="forgot-password.new-password"
      required
    />
    <label
      class="px-2 mt-2 form-label"
      appProtocolDroid="forgot-password.retype-password"
      >Re-type new Password</label
    >
    <input
      class="form-control m-2"
      type="password"
      name="confirmNewPassword"
      formControlName="confirmNewPassword"
      appProtocolDroidAttribute="placeholder"
      appProtocolDroid="forgot-password.new-password"
      required
    />
    <span class="m-2 text-danger error-message" *ngIf="resetPasswordError">{{
      resetPasswordError
    }}</span>

    <button
      type="submit"
      [disabled]="passwords.invalid"
      class="mt-3 btn btn-md btn-primary change-password-btn"
      appProtocolDroid="forgot-password.btn-new-password"
    >
      Reset password
    </button>
    <a
      class="m-2"
      [routerLink]="['/login']"
      appProtocolDroid="forgot-password.return-login"
      >return to login page</a
    >
  </form>
  <span class="info-text" *ngIf="state === 'success'">
    <p>
      <i
        class="text-success fas fa-check"
        appProtocolDroid="forgot-password.change-success"
      ></i>
    </p>
    <a [routerLink]="['/login']" appProtocolDroid="forgot-password.return-login"
      >return to login page</a
    >
  </span>
</div>
