import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class AntiAuthGuardService implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(): Observable<boolean> {
    return this.authService.getAuth().pipe(
      map(user => {
        const isFBProvided =
          user && user.providerData.length === 1 && user.providerData[0].providerId === 'facebook.com';
        if (!user || user.isAnonymous) {
          return true;
        } else {
          this.router.navigate([isFBProvided ? '/account-migration' : '/']);
          return false;
        }
      })
    );
  }
}
