import { AfterViewInit, Directive, ElementRef, Input, OnChanges, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { ProtocolDroidService } from '../services/protocol-droid.service';

type ProtocolDroidAttribute = 'innerHTML' | 'title' | 'placeholder'; // Could be any other HTML attribute

@Directive({
  selector: '[appProtocolDroid]'
})
export class ProtocolDroidDirective implements AfterViewInit, OnChanges, OnDestroy {
  @Input() appProtocolDroid: string;
  @Input() appProtocolDroidParams?: any;
  @Input() appProtocolDroidActive: boolean; // activate translation process on condition
  @Input() appProtocolDroidAttribute?: ProtocolDroidAttribute | ProtocolDroidAttribute[];
  private elem: HTMLElement;
  private subscription: Subscription;

  constructor(private elementRef: ElementRef, private protocolDroidService: ProtocolDroidService) {
    this.elem = this.elementRef.nativeElement;
    this.appProtocolDroidActive = true;
  }

  ngAfterViewInit() {
    this.subscription = this.protocolDroidService.onTranslationChangeEvent.subscribe(() => {
      this.translate();
    });
  }

  ngOnChanges() {
    this.translate();
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
  }

  private translate(): void {
    if (this.appProtocolDroidActive && this.appProtocolDroid) {
      if (!this.appProtocolDroidAttribute) {
        this.handleInnerHTML();
      } else {
        if (typeof this.appProtocolDroidAttribute === 'string') {
          this.appProtocolDroidAttribute = [this.appProtocolDroidAttribute];
        }
        this.appProtocolDroidAttribute.forEach(attribute => this.handleAttribute(attribute));
      }
    }
  }

  private handleAttribute(attribute: ProtocolDroidAttribute) {
    if (attribute === 'innerHTML') {
      this.handleInnerHTML();
    } else {
      // stringifying HTML content
      const div = document.createElement('div');
      div.innerHTML = this.protocolDroidService.translate(
        this.appProtocolDroid,
        this.elem.innerHTML, // current elem content is used as default value
        this.appProtocolDroidParams
      );
      const finalText = div.textContent || div.innerText || '';
      this.elem.setAttribute(attribute, finalText);
    }
  }

  private handleInnerHTML() {
    this.elem.innerHTML = this.protocolDroidService.translate(
      this.appProtocolDroid,
      this.elem.innerHTML, // current elem content is used as default value
      this.appProtocolDroidParams
    );
  }
}
