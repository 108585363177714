import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-feedback-spinner',
  templateUrl: './feedback-spinner.component.html',
  styleUrls: ['./feedback-spinner.component.scss']
})
export class FeedbackSpinnerComponent implements OnInit {
  constructor(private modalService: BsModalService) {}

  ngOnInit() {
    this.modalService.onShown.pipe(take(1)).subscribe(() => {
      this.shownHandler();
    });

    this.modalService.onHidden.pipe(take(1)).subscribe(() => {
      this.hiddenHandler();
    });
  }

  shownHandler(): void {} // Will be overriden by the spinner service

  hiddenHandler(): void {} // Will be overriden by the spinner service
}
