import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';

import { ProtocolDroidService } from '../protocol-droid/services/protocol-droid.service';

import { FeedbackMessageComponent } from '../../components/feedback/feedback-message/feedback-message.component';
import { FeedbackSpinnerComponent } from '../../components/feedback/feedback-spinner/feedback-spinner.component';

export type FeedbackType = 'message' | 'confirm' | 'offline';

export type FeedbackDecoration = 'none' | 'success' | 'info' | 'warning' | 'error';

type FeedbackDefaults = {
  [key in FeedbackDecoration | FeedbackType]?: {
    message?: string;
    messageDefault?: string;
    title: string;
    titleDefault: string;
  };
};

/**
 * This service is made for handling common modals spinner display
 */
@Injectable({
  providedIn: 'root'
})
export class FeedbackService {
  spinnerRef: BsModalRef;
  messageRef: BsModalRef;
  defaults: FeedbackDefaults;

  constructor(
    private modalService: BsModalService,
    private sanitizer: DomSanitizer,
    private protocolDroid: ProtocolDroidService
  ) {
    this.defaults = {
      error: {
        message: 'misc.errors.unknown',
        messageDefault: 'An unknown error occurred. Please contact support.',
        title: 'misc.modals.error-title',
        titleDefault: 'Oops...'
      },
      confirm: {
        title: 'misc.modals.confirm-title',
        titleDefault: 'Do you confirm?'
      },
      success: {
        title: 'misc.modals.success-title',
        titleDefault: 'Congratulations'
      }
    };
  }

  public showSpinner(): Promise<void> {
    return new Promise((resolve: any) => {
      const spinnerOptions = {
        class: 'modal-dialog-centered modal-spinner',
        keyboard: false,
        ignoreBackdropClick: true,
        initialState: {
          shownHandler: () => {
            setTimeout(() => {
              resolve();
            }, 0);
          }
        }
      };

      this.spinnerRef = this.modalService.show(FeedbackSpinnerComponent, spinnerOptions);
    });
  }

  public hideSpinner(): Promise<void> {
    if (this.spinnerRef) {
      return new Promise((resolve: any) => {
        this.spinnerRef.content.hiddenHandler = () => {
          setTimeout(() => {
            resolve();
          }, 0);
        };
        setTimeout(() => {
          this.spinnerRef.hide();
        }, 200);
      });
    } else return Promise.resolve();
  }

  /**
   * Note:
   * Now the showMessage function can handle translations automatically
   * you can pass an i18n path to the options 'message' and 'title'
   * as well as default values to 'messageDefault' and 'titleDefault' in case of translation failure
   *
   * the 'title' and 'titleDefault' are now part of the decoration, they will be set by default
   * if no values are in options and the type/decoration is 'confirm', 'error' or 'success'
   *
   * For the 'error' decoration, a common error message and messageDefault is also set by default
   * if no values are in options.
   *
   * @param options
   * @returns
   */
  public showMessage(
    options: {
      type?: FeedbackType;
      decoration?: FeedbackDecoration;
      back?: boolean;
      message?: string;
      bypassMessageAutoTranslate?: boolean;
      messageDefault?: string;
      messageParams?: any;
      title?: string;
      bypassTitleAutoTranslate?: boolean;
      titleDefault?: string;
      titleParams?: any;
    } = {}
  ): Promise<boolean> {
    const {
      type = 'message',
      decoration = 'none',
      back = false,
      bypassMessageAutoTranslate,
      messageParams,
      bypassTitleAutoTranslate,
      titleParams
    } = options;

    let { message, messageDefault, title, titleDefault } = options;
    let defaults;

    if (type === 'confirm') defaults = this.defaults[type];
    else if (decoration === 'error' || decoration === 'success') defaults = this.defaults[decoration];
    else defaults = {};

    message = message || defaults!.message || '';
    messageDefault = messageDefault || defaults!.messageDefault || '';
    title = title || defaults!.title || '';
    titleDefault = titleDefault || defaults!.titleDefault || '';

    const modalOptions: ModalOptions = {
      class: `modal-dialog-centered`,
      backdrop: 'static',
      keyboard: type !== 'offline',
      initialState: {
        title:
          bypassTitleAutoTranslate || !title ? title : this.protocolDroid.translate(title, titleDefault, titleParams),
        message:
          bypassMessageAutoTranslate || !message
            ? message
            : this.sanitizer.bypassSecurityTrustHtml(
                this.protocolDroid.translate(message, messageDefault, messageParams)
              ),
        type,
        decoration,
        back
      }
    };

    this.messageRef = this.modalService.show(FeedbackMessageComponent, modalOptions);

    if (!back) {
      return new Promise((resolve: any) => {
        this.messageRef.content.callback = (isConfirmed: boolean) => {
          resolve(isConfirmed);
          this.hideMessage();
        };
      });
    } else {
      return new Promise((resolve: any) => {
        this.messageRef.content.callback = (returned: any) => {
          resolve({ confirmed: returned.confirmed, back: returned.back });
          this.hideMessage();
        };
      });
    }
  }

  public hideMessage(): void {
    if (this.messageRef) {
      setTimeout(() => {
        this.messageRef.hide();
      }, 0);

      setTimeout(() => {
        this.messageRef;
      }, 1000);
    }
  }
}
