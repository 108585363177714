<div class="main" [class.day]="isDay">
  <div class="msg" [class.day]="isDay">
    <div appProtocolDroid="page-not-found.info-1">
      This page does not exist.
    </div>
    <a href="/" appProtocolDroid="page-not-found.info-2"
      >Click here to get back on track</a
    >
  </div>
</div>
