import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {
  isDay: boolean;

  constructor() {
    const currentHour = new Date().getHours();
    this.isDay = currentHour > 5 && currentHour < 20;
  }

  ngOnInit() {}
}
