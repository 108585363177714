import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class FacebookProvidedGuardService implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.authService.getAuth().pipe(
      map(user => {
        const isFBProvided =
          user && user.providerData.length === 1 && user.providerData[0].providerId === 'facebook.com';
        if (user && !isFBProvided) {
          this.router.navigate([user.isAnonymous ? '/login' : '/']);
          return false;
        } else {
          return true;
        }
      })
    );
  }
}
