import { Component, HostListener, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

import { FeedbackDecoration, FeedbackType } from '../../../services/feedback/feedback.service';
import { ProtocolDroidService } from '../../../services/protocol-droid/services/protocol-droid.service';

@Component({
  selector: 'app-feedback-message',
  templateUrl: './feedback-message.component.html',
  styleUrls: ['./feedback-message.component.scss']
})
export class FeedbackMessageComponent implements OnInit {
  title: string;
  message: string;
  type: FeedbackType;
  decoration: FeedbackDecoration;
  back: boolean;
  subscription: Subscription;
  private isConfirmed: boolean;
  private isBack: boolean;

  constructor(private modalRef: BsModalRef, public protocolDroidService: ProtocolDroidService) {}

  @HostListener('window:keyup', ['$event'])
  onEnter(event: KeyboardEvent) {
    if (event.key === 'Enter' && this.type === 'message') {
      this.dismiss();
    } else if (event.key === 'Enter' && this.type === 'confirm') {
      this.confirm();
    }
  }

  ngOnInit() {
    if (this.type === 'offline') {
      window.ononline = () => {
        this.modalRef.hide();
      };
    }
    this.isConfirmed = false;
    this.isBack = false;
  }

  confirm(): void {
    this.isConfirmed = true;
    this.isBack = false;
    this.hide();
  }

  dismiss(): void {
    this.hide();
  }

  onBack(): void {
    this.isConfirmed = false;
    this.isBack = true;
    this.hide();
  }

  hide(): void {
    if (!this.back) {
      this.callback(this.isConfirmed);
    } else {
      this.callback({ confirmed: this.isConfirmed, back: this.isBack });
    }
    if (this.type === 'offline') return;
    this.modalRef.hide();
  }

  callback(_: any): void {} // Will be overwritten at opening time
}
