import { ScrollingModule as ExperimentalScrollingModule } from '@angular/cdk-experimental/scrolling';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ColorPickerModule } from 'ngx-color-picker';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { ProtocolDroidModule } from './services/protocol-droid/protocol-droid.module';
import { UiElementsModule } from './ui-elements/ui-elements.module';

import { FeedbackMessageComponent } from './components/feedback/feedback-message/feedback-message.component';
import { FeedbackSpinnerComponent } from './components/feedback/feedback-spinner/feedback-spinner.component';
import { ModalPasswordComponent } from './components/modals/modal-password/modal-password.component';
import { ModalPlanProposalComponent } from './components/modals/modal-plan-proposal/modal-plan-proposal.component';
import { ModalRemoveCardComponent } from './components/modals/modal-remove-card/modal-remove-card.component';

@NgModule({
  imports: [
    BsDropdownModule,
    ColorPickerModule,
    CommonModule,
    DragDropModule,

    FormsModule,

    PerfectScrollbarModule,

    PopoverModule,
    UiElementsModule,
    ProtocolDroidModule,

    ReactiveFormsModule,
    RouterModule,

    ScrollingModule,

    ExperimentalScrollingModule
  ],
  declarations: [
    FeedbackMessageComponent,
    FeedbackSpinnerComponent,
    ModalPasswordComponent,
    ModalRemoveCardComponent,
    ModalPlanProposalComponent
  ],
  entryComponents: [FeedbackSpinnerComponent, FeedbackMessageComponent],
  exports: [
    BsDropdownModule,
    ColorPickerModule,
    CommonModule,
    DragDropModule,
    FormsModule,
    PerfectScrollbarModule,
    PopoverModule,
    UiElementsModule,
    ProtocolDroidModule,
    ReactiveFormsModule,
    RouterModule
  ]
})
export class SharedModule {}
