import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

import { environment } from '../../../../../environments/environment';

export type AuthorizedLangs = 'fr' | 'en';

@Injectable({
  providedIn: 'root'
})
export class ProtocolDroidService {
  // onTranslationChangeEvent is BehaviorSubject because we need first subscribe to fire
  public onTranslationChangeEvent: BehaviorSubject<null>;
  public currentLang$: BehaviorSubject<AuthorizedLangs>;
  private defaultLang: AuthorizedLangs;
  private translations: { [lang in AuthorizedLangs]?: any };

  public get fulldateFormat() {
    return this.translate('misc.formats.fulldate', 'M/d/yy') || 'medium';
  }

  private get currentLang() {
    return this.currentLang$.value;
  }

  private get translation() {
    return this.translations[this.currentLang];
  }

  constructor(private http: HttpClient) {
    this.defaultLang = 'en';
    this.translations = {};
    this.currentLang$ = new BehaviorSubject<AuthorizedLangs>(this.defaultLang);
    this.currentLang$.subscribe((lang: AuthorizedLangs) => this.loadLang(lang));
    this.onTranslationChangeEvent = new BehaviorSubject(null);
  }

  translate(address: string, defaultValue?: string, params?: any, silent?: boolean): string | any {
    if (!this.translation) return;

    const addressArray = address.split('.');
    let translatedText = addressArray.reduce(
      (addressTree: any, addressSegment: string) =>
        addressTree && addressTree[addressSegment] ? addressTree[addressSegment] : null,
      this.translation
    );

    if (!translatedText && !silent && environment.name !== 'production') {
      console.warn(`Missing Translation '${address}'`);
    } else if (translatedText) {
      const matchedParams = translatedText.match(/\${\w+}/g);

      if (matchedParams) {
        translatedText = matchedParams.reduce(
          (text: string, match: string) => this.replaceStringByParam(text, match, params),
          translatedText
        );
      }
    }

    return translatedText || defaultValue || '';
  }

  setLang(lang: AuthorizedLangs) {
    // handling langs formats like fr-FR
    const parsedLang = RegExp('fr').test(lang) ? 'fr' : this.defaultLang;
    if (parsedLang === this.currentLang) return;
    this.currentLang$.next(parsedLang);
  }

  private loadLang(lang: AuthorizedLangs) {
    return this.http
      .get(`assets/i18n/${lang}.json`)
      .pipe(take(1))
      .subscribe((translation: any) => {
        this.translations[lang] = translation;
        this.onTranslationChangeEvent.next(null);
      });
  }

  private replaceStringByParam(text: string, match: string, params: Record<string, string>) {
    const paramKey = match.substring(2, match.length - 1);

    if (!params || !params.hasOwnProperty(paramKey)) {
      console.error(`Missing Translation param '${paramKey}' in '${text}'`);
    }

    return text.split(match).join(params[paramKey]);
  }
}
