import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { get } from 'lodash';
import { combineLatest, Subscription } from 'rxjs';

import { ProtocolDroidService } from './shared/services/protocol-droid/services/protocol-droid.service';
import { CoreSessionService } from './shared/services/session/core-session.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[];
  constructor(private titleService: Title, private droid: ProtocolDroidService, private _session: CoreSessionService) {
    this.subscriptions = [];
  }
  ngOnInit() {
    this.droid.setLang(get(navigator, 'language') || get(navigator, 'userLanguage'));
    this.subscriptions.push(
      // flexible "Title"
      combineLatest([this._session.routerEvent$, this.droid.onTranslationChangeEvent]).subscribe(([event]) => {
        const title = this.droid.translate('idechat.header.' + event!.location, 'undefined', null, true);
        if (title !== 'undefined') {
          this.titleService.setTitle(title + ' | Ideta');
        } else {
          this.titleService.setTitle('Ideta Nuki');
        }
      })
    );
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }
}
